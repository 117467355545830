.insightsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 24rem);
    gap: 2rem;
}

.insightsGridItem {
    position: relative;
    height: 15rem;
    width: 24rem;
    overflow: hidden;

    img {
        height: 100%;
        width: auto;
    }
}
