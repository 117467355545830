@import '../../styles/_colors';
@import '../../styles/_screen_sizes';

.hiddenContainer {
    overflow: hidden;
    visibility: hidden;
    height: 0;
}

.title {
    font-weight: 200;
    padding: 0 0.4rem;
}

.text {
    width: 100%;
    font-size: 2rem;
}

.visibleTitle {
    display: inline-block;
    white-space: nowrap;
}

.gray {
    background-color: $gray;
    color: $white;
}

.white {
    background-color: $white;
    color: $gray;
}

.lime_green {
    background-color: $limeGreen;
    color: $gray;
}

.green {
    background-color: $green;
    color: $white;
}

.left {
    margin-right: 2rem;
    padding-left: 2rem;
    text-align: left;

    @media only screen and (min-width: $sm) {
        margin-right: 4.5rem;
        padding-left: 4.5rem;
    }
}

.right {
    margin-left: 2rem;
    padding-right: 2rem;
    text-align: right;
    @media only screen and (min-width: $sm) {
        margin-left: 4.5rem;
        padding-right: 4.5rem;
    }
}
