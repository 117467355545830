@import '../../styles/_colors';
@import '../../styles/_screen_sizes';

.card {
    display: block;
    color: $gray;
    text-decoration: none;
    overflow-wrap: break-word;

    > span {
        display: block;
        font-size: 1.44rem;
        font-weight: 400;
        margin-bottom: 0.75rem;
    }

    > h2 {
        margin: 0.75rem 0;
        font-size: 1.5rem;
    }

    > h3 {
        margin: 0 0 0.75rem;
        font-size: 1.125rem;
    }

    &:hover {
        > span,
        > h2,
        > h3 {
            color: $green;
        }

        .cardImage {
            opacity: 0.7;
        }
    }

    margin-bottom: 2rem;
    @media only screen and (min-width: $sm) {
        margin-bottom: 0;
    }
}

.cardImage {
    width: 100%;
    opacity: 1;
    transition: opacity 150ms;
}
