@import '../../styles/_screen_sizes';
@import '../../styles/_colors';

.button {
    display: block;
    border: solid 0.2rem;
    padding: 0.8rem 0;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    transition: background-color 100ms linear;
    text-align: center;
    text-decoration: none;

    &:hover,
    &:active {
        background-color: transparent;
        cursor: pointer;
    }

    @media only screen and(min-width: $sm) {
        width: 34%;
    }
}

.gray {
    background-color: $gray;
    border-color: $gray;
    color: $white;

    &:hover,
    &:active {
        color: $gray;
    }
}

.white {
    background-color: $white;
    border-color: $white;
    color: $gray;

    &:hover,
    &:active {
        color: $white;
    }
}

.green {
    background-color: $green;
    border-color: $green;
    color: $white;

    &:hover,
    &:active {
        color: $gray;
    }
}

.limeGreen {
    background-color: $limeGreen;
    border-color: $limeGreen;
    color: $gray;

    &:hover,
    &:active {
        color: $gray;
        background-color: transparent;
    }
}

.transparent {
    background-color: transparent;
    border-color: $white;
    color: $white;

    &:hover,
    &:active {
        color: $gray;
        background-color: $white;
    }
}