.inputTable {
    border-spacing: 0;
    width: 100%;

    tr:nth-child(2n) {
        background-color: #f2f2f2;
    }

    td {
        padding: 5px;
    }
}

.input {
    width: 100%;
}

.textarea {
    width: 100%;
    resize: none;
    height: 8em;
}

.tagContainer {
    margin: 0 -2px;
}

.tag {
    background-color: #dddddd;
    margin: 2px;
    border: 1px solid #aaaaaa;
    display: inline-block;
    padding: 2px 4px;
    border-radius: 2px;
    cursor: pointer;
}

.activeTag {
    background-color: #aaaaaa;
}

.imageInput {
    display: inline-block;
    position: relative;

    button {
        position: absolute;
        bottom: 10px;
        right: 5px;
    }
}

.deleteButton {
    background-color: #ff0000;
    border: 0;
    padding: 0.125rem 0.5rem;
    cursor: pointer;

    &:hover {
        background-color: #bb0000;
    }
}

.saveButton {
    background-color: #00bb00;
    border: 0;
    padding: 0.25rem 0.75rem;
    cursor: pointer;

    &:hover, &:disabled {
        background-color: #009900;
    }
}