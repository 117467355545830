@import '../../../styles/_screen_sizes';
@import '../../../styles/_colors';

.dateTitle {
    margin: 3rem 2rem 1rem 2rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: $gray;

    @media only screen and (min-width: $sm) {
        margin: 3rem 4.5rem 1rem 4.5rem;
    }
}

.topTitle {
    margin-bottom: 1rem;
    font-size: 3rem;
}

.subtitle {
    margin-bottom: 1rem;
    font-size: 1.75rem;
}

.speaker {
    font-size: 2.5rem;
}

.tagContainer {
    margin: 2rem 2rem 0 2rem;

    @media only screen and (min-width: $sm) {
        margin: 2rem 4.5rem 0 4.5rem;
    }
}

.buttonLine,
.talkDetailContainer {
    margin: 4rem 2rem 2rem 2rem;
    font-size: 1.5rem;

    @media only screen and (min-width: $sm) {
        margin: 4rem 4.5rem 2rem 4.5rem;
    }
}

.talkDetailContainer {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    @media only screen and (min-width: $md) {
        flex-direction: row;
    }
}

.talkDescription {
    white-space: pre-line;

    ul {
        margin-bottom: 0;
        white-space: normal;
        list-style: none;
        line-height: 1.75;

        li:before {
            content: '>';
            margin-left: -1em;
            display: inline-block;
            width: 1em;
        }
    }
}

.halfWidth {
    width: 100%;

    &:not(:first-child) {
        margin-top: 2rem;
    }

    @media only screen and (min-width: $md) {
        width: calc(50% - 3rem);
        margin-top: 0 !important;
    }
}

.fullWidth {
    width: 100%;
}

.buttons {
    margin-top: 4rem;

    a {
        margin-bottom: 2rem;
    }

    a:last-of-type {
        margin-bottom: 0;
    }

    @media only screen and (min-width: $sm) {
        display: flex;
        &.between {
            justify-content: space-around;
        }

        justify-content: space-evenly;

        a {
            margin-bottom: 0;
            width: 30%;
        }
    }

    @media only screen and (min-width: $md) {
        justify-content: flex-start;
    }
}
