@import '../../styles/_screen_sizes';

.playerWrapper {
    position: relative;
    padding-top: 56.25%;
}

.reactPlayer,
.accept {
    position: absolute;
    top: 0;
    left: 0;
}

.accept {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: hsla(0, 0%, 0%, 0.75);
    color: white;
    font-weight: bold;
    font-size: 0.6em;
    padding: 2rem;

    @media only screen and(min-width: $sm) {
        font-size: 0.8em;
    }
}

.play {
    height: 1em;
    margin-bottom: -0.15em;
    margin-right: 0.33em;
}

.tos {
    text-align: center;
    font-weight: 400;
    margin-bottom: 1em;
}

.privacyLink {
    font-weight: 600;
}

.acceptButton {
    width: 100%;
    font-size: 0.8em;

    @media only screen and(min-width: $sm) {
        width: 50%;
    }
}
