@import '../../../styles/_screen_sizes';
@import '../../../styles/_colors';

.navigation {
    height: 100%;

    @media only screen and (min-width: $sm) {
        height: auto;
    }
}

.largeNavigation {
    display: none;
    font-size: 1.25rem;
    white-space: nowrap;

    @media only screen and (min-width: $sm) {
        display: flex;
    }
}

.navLink {
    color: $limeGreen;
    margin: 1rem 0;
    text-transform: uppercase;
    text-decoration: none;
    font-size: inherit;
    font-weight: 300;

    &:hover,
    &.active {
        color: $white;
    }

    @media only screen and (min-width: $sm) {
        margin: 1.5rem 0.5rem;

        &:last-child() {
            margin-right: 0;
        }

        &:first-child() {
            margin-left: 0;
        }
    }

    @media only screen and (min-width: $md) {
        margin: 1.5rem;
    }
}

.burgerMenu {
    @media only screen and (min-width: $sm) {
        display: none;
    }

    height: 100%;
}

.burgerMenuOverlay {
    position: fixed;
    display: none;
    width: 100%;
    background-color: $gray;
    left: 0;

    flex-direction: column;
    font-size: 1.5rem;
    align-items: flex-end;
    padding: 0 2rem;

    &.open {
        display: flex;
    }
}

.burgerToggle {
    display: flex;
    height: 100%;
    padding: 0 2rem;
    margin: 0;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    color: $limeGreen;
    border: none;
}

.hideIcon {
    display: none;
}
