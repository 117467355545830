@import '../../styles/_screen_sizes';
@import '../../styles/_colors';

.container {
    position: relative;
    background-color: $gray;

    padding-bottom: 2rem;

    @media only screen and (min-width: $sm) {
        background-color: initial;
        padding-bottom: 0;
    }
}

.absolut {
    @media only screen and (min-width: $sm) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.image {
    display: block;
    width: 100%;
    @media only screen and (min-width: $sm) {
        height: 432px;
        object-fit: cover;
    }
}

.dateTitle {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 2rem;

    @media only screen and(min-width: $sm) {
        font-size: 2rem;

        span {
            background-color: $gray;
            color: $white;
        }
    }
}

.talkTitle {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;

    @media only screen and(min-width: $sm) {
        font-size: 3rem;
    }
}

.talkSubtitle {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    @media only screen and(min-width: $sm) {
        font-size: 1.75rem;
    }
}

.speakers {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;

    @media only screen and(min-width: $sm) {
        font-size: 3rem;
    }
}

.buttons {
    margin: 4.5rem 2rem 2rem;
    @media only screen and (min-width: $sm) {
        display: flex;
        justify-content: flex-end;
        margin: 0 4.5rem;
    }
}

.button {
    margin-top: 0.5rem;
    margin-bottom: 0;

    @media only screen and (min-width: $sm) {
        width: 18rem;
        margin-left: 2rem;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        &:first-of-type {
            margin-left: 0;
        }
    }
}

.lime:hover {
    color: $limeGreen;
    background-color: transparent;
}
