@import '../../styles/_colors';
@import '../../styles/_screen_sizes';

.disclaimer {
    display: flex;
    align-items: center;

    .text {
        font-size: 1.25rem;
    }

    margin-bottom: 2rem;
}

.checkbox {
    margin-right: 1rem;
}

.input {
    border: 2px solid $white;
    color: $white;
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
}

.submitButton {
    margin: auto;

    @media only screen and (min-width: $sm) {
        width: 34%;
    }
}

.link {
    color: $limeGreen;
    text-decoration: none;
    font-size: inherit;
    font-weight: 300;

    &:hover,
    &.active {
        color: $white;
    }
}
