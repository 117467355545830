@import '../../styles/_colors';
@import '../../styles/_screen_sizes';

.grid {
    display: block;
    justify-content: space-between;

    @media only screen and (min-width: $sm) {
        display: grid;
        grid-template-columns: repeat(auto-fill, 18rem);
        gap: 2rem;
    }
}
