@import '../../../styles/_screen_sizes';

.talksWrapper {
    margin: 4.5rem 0;
}

.grid {
    padding: 4.5rem 2rem;
    display: block;

    justify-content: space-between;

    @media only screen and (min-width: $sm) {
        padding: 4.5rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, 18rem);
        gap: 2rem;
    }
}

.heroimage {
    width: 100%;
    height: auto;
}

.loadMoreButton {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 2rem;
    @media only screen and(min-width: $sm) {
        margin: 2rem 4.5rem;
    }
}

.gridTitle {
    font-size: 3rem !important;
}

.gridTitleFilterWrapper {
    display: flex;

    flex-direction: column-reverse;

    @media only screen and (min-width: $sm) {
        flex-direction: row;
        align-items: flex-end;
    }
}
