.hide {
    display: none;
}

.checkbox {
    display: flex;
    justify-content: flex-end;
    text-align: end;
    word-break: break-all;
    align-items: center;
    font-size: 1rem;
    width: 50%;
    padding: 0.5rem;
    cursor: pointer;
    font-weight: 600;

    &.show {
        justify-content: space-between;
    }
}

.cross {
    width: 1.5rem;
    height: auto;
    padding-right: 0.5rem;
}
