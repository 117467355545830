@import '../../styles/_screen_sizes';
@import '../../styles/_colors';

.header {
    z-index: 10;
    position: fixed;
    background-color: $gray;
    width: 100%;
    transition: height 10ms linear;

    @media only screen and (max-width: 767px) {
        height: 72px !important;
    }
}

.spacer {
    background-color: $gray;
    @media only screen and (max-width: 767px) {
        height: 72px !important;
    }
}

.container {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 0 0 2rem;

    @media only screen and (min-width: $sm) {
        align-items: flex-end;
        padding: 0 4.5rem;
    }
}
