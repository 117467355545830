@import '../../styles/_colors';

.adminContainer {
    background-color: $green;
}

.container {
    max-width: 1440px;
    margin: 2rem auto;
}

.spacer {
    flex-grow: 1;
}

.adminNav {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0.5rem 0;
}

.link {
    text-decoration: none;
    font-size: 1.5rem;
    margin: 0 1rem;
    color: $white;
    cursor: pointer;

    &:hover {
        color: $gray;
    }
}
