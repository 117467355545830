/* LOCAL GOOGLE FONTS */

/* source-sans-pro-200 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/source-sans-pro-v13-latin-200.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
        url('../fonts/source-sans-pro-v13-latin-200.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/source-sans-pro-v13-latin-200.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/source-sans-pro-v13-latin-200.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/source-sans-pro-v13-latin-200.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/source-sans-pro-v13-latin-200.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-300 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/source-sans-pro-v13-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('../fonts/source-sans-pro-v13-latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/source-sans-pro-v13-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/source-sans-pro-v13-latin-300.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/source-sans-pro-v13-latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/source-sans-pro-v13-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/source-sans-pro-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
        url('../fonts/source-sans-pro-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/source-sans-pro-v13-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/source-sans-pro-v13-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/source-sans-pro-v13-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/source-sans-pro-v13-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
        url('../fonts/source-sans-pro-v13-latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/source-sans-pro-v13-latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/source-sans-pro-v13-latin-600.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/source-sans-pro-v13-latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/source-sans-pro-v13-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/source-sans-pro-v13-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('../fonts/source-sans-pro-v13-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/source-sans-pro-v13-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/source-sans-pro-v13-latin-700.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/source-sans-pro-v13-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/source-sans-pro-v13-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-code-pro-300 - latin */
@font-face {
    font-family: 'Source Code Pro';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/source-code-pro-v11-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Source Code Pro Light'), local('SourceCodePro-Light'), url('../fonts/source-code-pro-v11-latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/source-code-pro-v11-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/source-code-pro-v11-latin-300.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/source-code-pro-v11-latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/source-code-pro-v11-latin-300.svg#SourceCodePro') format('svg'); /* Legacy iOS */
}
/* source-code-pro-regular - latin */
@font-face {
    font-family: 'Source Code Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/source-code-pro-v11-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Source Code Pro Regular'), local('SourceCodePro-Regular'),
        url('../fonts/source-code-pro-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/source-code-pro-v11-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/source-code-pro-v11-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/source-code-pro-v11-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/source-code-pro-v11-latin-regular.svg#SourceCodePro') format('svg'); /* Legacy iOS */
}
/* source-code-pro-600 - latin */
@font-face {
    font-family: 'Source Code Pro';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/source-code-pro-v11-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Source Code Pro SemiBold'), local('SourceCodePro-SemiBold'),
        url('../fonts/source-code-pro-v11-latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/source-code-pro-v11-latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/source-code-pro-v11-latin-600.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/source-code-pro-v11-latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/source-code-pro-v11-latin-600.svg#SourceCodePro') format('svg'); /* Legacy iOS */
}
