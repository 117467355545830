@import '../../../styles/_colors';
@import '../../../styles/_screen_sizes';

.container {
    margin: 2.75rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $sm) {
        margin: 2.75rem 4.5rem 0 4.5rem;
        flex-direction: row;
    }
}

.sectionTitle {
    margin-top: 4.5rem;
}

.form {
    margin-right: 0;

    @media only screen and (min-width: $sm) {
        margin-right: 2rem;
    }
}

.text {
    color: #1e1e1e;
}

.input {
    border: 2px solid $gray;
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
}

.textarea {
    resize: none;
    height: 10rem;
}

.disclaimer {
    display: flex;
    align-items: center;
}

.checkbox {
    margin-right: 1rem;
}

.centeredButton {
    margin: 2rem auto;

    @media only screen and (min-width: $sm) {
        min-width: 40%;
    }
}

.externalLink {
    color: inherit;
    text-decoration: none;
}

.spacer {
    height: 2rem;
}

.contactDetails {
    display: flex;
    flex-direction: column;
    margin: 0;
    white-space: nowrap;
    font-size: 1.25rem;

    > div {
        display: flex;

        ~ div {
            margin-top: 0.25rem;
        }

        > svg {
            margin-right: 2rem;
        }
    }

    @media only screen and (min-width: 768px) {
        // margin-left: 2rem;
    }
}

.svg {
    width: 1.25rem;
    height: 1.25rem;
}
