@import '../../../styles/_screen_sizes';

.logo {
    align-self: center;
    height: 62.5%;
}

.logoLink {
    display: block;
    height: 100%;
}

.logoSvg {
    height: 100%;
    width: auto;
    display: none;

    @media only screen and (min-width: $sm) {
        display: block;
    }
}

.logoSvgSmall {
    height: 100%;
    width: auto;
    display: block;
    @media only screen and (min-width: $sm) {
        display: none;
    }
}
