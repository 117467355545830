@import '../../styles/_colors';
@import '../../styles/_screen_sizes';

.aboutInfoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.aboutInfoBlock {
    font-size: 1.25rem;
    padding: 2rem 0;

    max-width: 45%;

    @media only screen and (min-width: $sm) {
        max-width: 20%;
    }

    svg {
        display: block;
        width: 6rem;
        height: 6rem;
        padding-bottom: 0.5rem;
    }
}
