@import '../../styles/_screen_sizes';
@import '../../styles/_colors';

.containerClassName {
    padding: 3rem 0;
}

.title {
    margin-bottom: 2rem;
    font-size: 2.5rem !important;
}

.content {
    padding: 0 2rem;

    @media only screen and (min-width: $sm) {
        padding: 0 4.5rem;
    }

    font-size: 1.4rem;
}

.bgGray {
    color: $white;
    background-color: $gray;
}

.bgWhite {
    background-color: $white;
}

.bgGreen {
    background-color: $limeGreen;
}
