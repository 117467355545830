@import '../../styles/_screen_sizes';
@import '../../styles/_colors';

.dropdown {
    position: relative;
    max-width: 100%;
    margin: 2rem;
    margin-top: 0;

    @media only screen and (min-width: $sm) {
        margin-bottom: 0;
        min-width: 20rem;
        margin-right: 4.5rem;
    }
}

.toggleButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    border: none;
    border-bottom: 2px solid $gray;
    background-color: transparent;
    padding: 0.5rem 0.2rem 0.2rem 0.2rem;
    width: 100%;
}

.filterButton {
    width: 100%;
    height: 2rem;
    font-size: 1.25rem;
    padding: 0;
}

.menu {
    display: none;
    position: absolute;
    padding: 0.5rem 0.2rem;
    width: 100%;
    background-color: white;
    border: 1px solid $gray;
    border-top: none;
}

.tagMap {
    display: flex;
    flex-wrap: wrap;
    color: $gray;
}

.hide {
    display: none;
}

.show {
    display: block;
}
