@import '../../styles/_screen_sizes';
@import '../../styles/_colors';

.footer {
    max-width: 1440px;
    margin: auto;
    padding-bottom: 2.5rem;
}

.link {
    display: block;
    text-decoration: none;
    color: black;
    margin-bottom: 0.5rem;

    :hover {
        color: $gray;
    }
}

.footerLinks {
    padding: 0 2rem;

    @media only screen and (min-width: $sm) {
        padding: 0 4.5rem;
        display: flex;
        justify-content: space-between;
    }
}

.third {
    margin-bottom: 0.5rem;
    @media only screen and (min-width: $sm) {
        margin: 0;

        width: 33%;

        &.center {
            text-align: center;
        }

        &.right {
            text-align: right;
        }
    }
}
