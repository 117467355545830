@import '../../styles/_colors';
@import '../../styles/_screen_sizes';

.wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;

    margin: 4.5rem 2rem;

    @media only screen and(min-width: $sm) {
        margin: 4.5rem;
    }
}

.talkInformation {
    width: 100%;
    color: $gray;
    padding-right: 0;

    @media only screen and(min-width: $sm) {
        padding-right: 2rem;
        min-width: 35%;
        height: 100%;
    }
}

.talkDate {
    font-size: 1.25rem;
    font-weight: 400;
    color: $green;
}

.talkTitle {
    font-size: 3rem;
    margin: 0.75rem 0;
}

.talksTags {
    font-size: 1.25rem;
}

.button {
    width: 100%;
    margin: 1rem 0;
}

.talkImage {
    display: none;

    @media only screen and(min-width: $md) {
        display: block;
    }
}
