@import '../../../styles/_colors';

.talksTable {
    border-collapse: collapse;
    margin-top: 2rem;
    width: 100%;
    font-size: 1.2rem;

    thead {
        tr {
            color: $white;
            background-color: $green !important;
        }
    }

    td {
        border: 1px solid #ddd;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    tr:hover {
        background-color: #ddd;
    }
}

.tableRowLink {
    color: inherit;
    text-decoration: none;
}

.rowDeleteButton,
.addTalk {
    background-color: #eb4242;
    color: $white;
    border: none;
    border-radius: 0.1rem;
    cursor: pointer;
    width: 100%;

    &:hover {
        background-color: #be1f1f;
    }
}

.addTalk {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    background-color: $green;
    &:hover {
        background-color: $green;
    }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;

    button {
        margin: 0 1.25rem;
    }
}
