@import '../../../styles/_screen_sizes';

.textContainer {
    margin: 2.75rem 2rem 4.5rem 2rem;
    display: flex;
    flex-direction: column;

    font-size: 1.2rem;
    line-height: 1.25;

    @media only screen and (min-width: $sm) {
        margin: 2.75rem 4.5rem 4.5rem 4.5rem;
    }
}

.sectionTitle {
    margin-top: 4.5rem;
}
