@import '../../styles/_colors';
@import '../../styles/_screen_sizes';

.bulletPointWrapper {
    margin: 0 2rem;

    @media only screen and (min-width: $sm) {
        margin: 0 4.5rem;
    }

    .centeredLinkButton {
        margin-bottom: 3rem;
    }
}

.bulletPointList {
    font-size: 1.5rem;

    ul {
        margin: 0;
        @media only screen and (min-width: $sm) {
            margin: 1rem 0;
        }
    }

    li {
        padding: 1rem;
    }

    @media only screen and (min-width: $sm) {
        display: flex;
    }
}

.centeredLinkButton {
    margin: auto;
}

.interested {
    padding: 0 2rem;
    padding-bottom: 3rem;

    @media only screen and (min-width: $sm) {
        padding: 0 4.5rem;
        padding-bottom: 3rem;
    }

    background-color: $limeGreen;

    h2 {
        font-size: 2.5rem;
        text-align: center;
        margin-top: 0;
        margin-bottom: 2rem;
    }
}

.techTalkTeam {
    background-color: $gray;
    padding: 3rem 0;
}

.techTalkTeamContent {
    padding: 1rem 2rem;

    @media only screen and (min-width: $sm) {
        padding: 1rem 4.5rem;
    }

    font-size: 1.25rem;
    color: $white;
}

.techTalkTeamTitle {
    font-size: 2.5rem;
}
