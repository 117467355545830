@keyframes spinning-circle {
    0% {
        stroke-dashoffset: 0;
        stroke-dasharray: 150.6 100.4;
    }
    50% {
        stroke-dasharray: 1 250;
    }
    100% {
        stroke-dashoffset: 502;
        stroke-dasharray: 150.6 100.4;
    }
}

.spinner {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: spinning-circle;
    animation-timing-function: linear;
}
