@import '../../styles/_colors';
@import '../../styles/_screen_sizes';

.dateTitle {
    margin: 3rem 2rem 1rem 2rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: $gray;

    @media only screen and (min-width: $sm) {
        margin: 3rem 4.5rem 1rem 4.5rem;
    }
}

.bigTitle {
    font-size: 3.5rem;
    margin-bottom: 1rem;
}

.description {
    padding: 0 2rem;

    @media only screen and (min-width: $sm) {
        padding: 0 4.5rem;
    }

    font-size: 1.5rem;
    white-space: pre-line;
}

.unannounced {
    font-size: 3.5rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.form {
    padding: 4.5rem 2rem;

    @media only screen and (min-width: $sm) {
        padding: 4.5rem;
        flex-direction: row;
    }
}

.centeredButton {
    margin: 2rem auto;

    @media only screen and (min-width: $sm) {
        min-width: 40%;
    }
}

.textInputs {
    display: flex;
    flex-direction: column;

    > div {
        width: 100%;
    }

    > div:first-of-type {
        margin-right: 0.5rem;
    }

    label {
        display: block;
    }

    @media only screen and (min-width: $sm) {
        flex-direction: row;
    }
}

.input {
    border: 2px solid $gray;
    color: $gray;
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
}

.disclaimer {
    display: flex;
    align-items: center;
}

.checkbox {
    margin-right: 1rem;
}
