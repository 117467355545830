@import '../../styles/_screen_sizes';

.container {
    position: relative;
}

.absolut {
    @media only screen and (min-width: $sm) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.image {
    width: 100%;
    height: auto;
    display: block;
}

.topTitle {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;

    @media only screen and(min-width: $sm) {
        font-size: 3.5rem;
    }
}

.speaker {
    font-size: 3rem;
    @media only screen and(min-width: $sm) {
        font-size: 4.5rem;
    }
}
