@import '../../styles/_colors';
@import '../../styles/_screen_sizes';

.social {
    padding: 4.5rem 2rem;
    display: flex;
    justify-content: space-between;

    @media only screen and (min-width: $sm) {
        padding: 4.5rem;
        justify-content: center;
    }

    a {
        color: $gray;
        text-align: center;
        :hover {
            color: $limeGreen;
        }
    }

    svg {
        width: 5rem;
        height: 5rem;
        color: inherit;

        max-width: 85%;

        @media only screen and (min-width: $sm) {
            padding: 0.5rem;
            width: 7rem;
            height: 7rem;
        }
    }
}
