.wrapper {
    display: flex;
    justify-content: center;
    margin: 10rem;

    > svg {
        width: 5rem;
        height: 5rem;
    }
}
